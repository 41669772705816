import React from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import LogoWhite from './../assets/avetta_logo_white.png';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#333', color: 'white', padding: '40px 0' }}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                        <IconButton color="inherit">
                            <img src={LogoWhite} alt="Avetta Logo" style={{ height: '20px' }} />
                        </IconButton>
                
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Company</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><a href="https://www.avetta.com/en-au/about-us" style={{ color: 'white', textDecoration: 'none' }}>About</a></li>
                            <li><a href="https://www.avetta.com/en-au/contact-us" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</a></li>
                            {/* ... add more links here */}
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Products</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><a href="https://www.avetta.com/en-au/avetta-one" style={{ color: 'white', textDecoration: 'none' }}>Avetta One</a></li>
                            {/* <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Connect</a></li>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Pegasusme Workforce</a></li>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Workforce</a></li> */}
                            {/* ... add more links here */}
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6">Resources</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><a href="https://help.avetta.com/" style={{ color: 'white', textDecoration: 'none' }}>Help Center</a></li>
                            <li><a href="https://www.avetta.com/en-au/resource-library" style={{ color: 'white', textDecoration: 'none' }}>Resource Library</a></li>
                        </ul>
                    </Grid>
                </Grid>
                <Typography align="center" style={{ marginTop: '40px' }}>
  <strong>© {new Date().getFullYear()} Avetta, LLC All rights reserved.</strong> As is our customers' request, content on this website is the copyrighted property of Avetta and should not be re-printed or excerpted without express permission from Avetta.
</Typography>

            </Container>
        </footer>
    );
}

export default Footer;
