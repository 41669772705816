import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logoWhite from './../assets/avetta_logo_white.png';
import logoColor from './../assets/1+Avetta+log.png';

const Header = () => {
    const [isShrunk, setIsShrunk] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 50; // Choose the scroll threshold as you see fit.
            if (show) {
                setIsShrunk(true);
            } else {
                setIsShrunk(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerStyle = isShrunk ? {
        height: '80px',
        minHeight: '80px', // override minHeight
        padding: '0', // adjust padding if needed
        backgroundColor: 'white',
        transition: 'backgroundColor 0.3s' // Smooth transition when changing background color
    } : {
        height: '80px',
        minHeight: '80px', // override minHeight
        padding: '0', // adjust padding if needed
        backgroundColor: 'transparent',
        transition: 'backgroundColor 0.3s' // Smooth transition when changing background color
    };
    

    const logoStyle = isShrunk ? {
        color: 'black' // Shrunk state
    } : {
        color: 'white' // Expanded state
    };

    const textColor = isShrunk ? '#2d67c5' : 'white';

    const menuStyle = {
        color : textColor, 
        fontSize : '16px',
        
    };

    const bigButtonStyle = {
        backgroundColor: isShrunk ? 'white' : '#2d67c5',
        padding: '10px 20px',
        borderRadius: '5px',
        color: textColor,
        textTransform: 'uppercase',
        fontSize: '16px',
        border: isShrunk ? `1px solid ${textColor}` : 'none',
    };

    return (
        <AppBar position="fixed" style={headerStyle} elevation={isShrunk ? 4 : 0}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '80px' }}>
                
                <Link to="/" style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <img src={isShrunk ? logoColor : logoWhite} alt="Avetta Logo" style={{ height: '60px' }} />
                </Link>
    
                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button color="inherit" style={menuStyle} component={Link} to="https://Avetta.com">Avetta</Button>
                    <Button color="inherit" style={menuStyle} component={Link} to="https://help.avetta.com/">Help Center</Button>
                    <Button color="inherit" style={menuStyle} component={Link} to="https://www.avetta.com/clients/solutions/platform/integrations">Integrations</Button>
                </div>
    
                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Link to="https://www.avetta.com/en-au/getting-registered" style={{ textDecoration: 'none' }}>
                    <Button style={bigButtonStyle}>Join the Network</Button>
                </Link>
                </div>
                
            </Toolbar>
        </AppBar>
    );
    
}

export default Header;
