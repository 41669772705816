// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'; // import the header component
import Footer from './components/Footer';
import HomePage from './HomePage'; // sample page component
//import APIDocsPage from './APIDocsPage'; // sample page component
//import AboutPage from './AboutPage'; // sample page component

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <div style={{ paddingTop: '80px' }}> {/* Added padding here */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/api-docs" element={<APIDocsPage />} /> */}
          {/* <Route path="/integrations" element={<Integrations />} /> */}
          {/* Add more routes as needed */}
        </Routes>
        </div>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
