import React from 'react';
import { Container, Grid, Paper, Typography, Box } from '@mui/material';
import ConnectIcon from '../src/assets/connect-icon.svg';
import PegasusIcon from '../src/assets/pegasus-icon.svg';
import WorkIcon from '../src/assets/worker-icon.svg';
import { Link } from 'react-router-dom';

const HomePage = () => {

    const heroPanelStyle = {
        padding: '140px 0 60px',
        background: 'linear-gradient(to right, #0074d9, #00c0e2)',
        color: 'white',
        textAlign: 'center',
        marginTop: '-80px'
    };
    
    const heroTitleStyle = {
        fontSize: '48px',
        marginBottom: '20px'
    };
    
    const heroDescriptionStyle = {
        fontSize: '24px'
    };

    const panelLinkStyle = {
        textDecoration: 'none',
        color: 'inherit' // or any desired color
    };

    const panels = [
        {
            title: "Connect APIs",
            icon: <img src={ConnectIcon} alt="Connect APIs" style={{ width: 60, height: 60 }} />,
            link: "https://docs.connect.avetta.com",
            status: "live"
        },
        {
            title: "Pegasus WorkForce APIs",
            icon: <img src={PegasusIcon} alt="Pegasus WorkForce APIs" style={{ width: 60, height: 60 }} />,
            link: "https://docs.pegasus.avetta.com/",
            status: "live"
        },
        {
            title: "Worker Management API",
            icon: <img src={WorkIcon} alt="WorkForce API" style={{ width: 60, height: 60 }} />,
            link: "https://docs.worker.avetta.com", 
            status: "live"
        }
    ];

    const styles = {
        overlay: {
            position: 'relative',
        },
        overlayBefore: {
            content: '"Coming Soon"',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black background
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '2rem',
            fontWeight: 'bold',
            zIndex: 1, // Overlay content below
            pointerEvents: 'none', // Optional: to disable interaction
        }
    };

    return (
        <div>
            <div style={heroPanelStyle}>
                <div style={heroTitleStyle}>API Documentation</div>
                <div style={heroDescriptionStyle}>
                    Learn more about the APIs available for Avetta One products. Integrate Avetta solutions with Information Systems across your organization.
                </div>
            </div>

            <div style={{ paddingTop: '120px', paddingBottom: '120px' }}>
                <Container>
                    <Grid container spacing={4}>
                        {panels.map((panel, index) => (
                            <Grid item xs={12} sm={4} key={index}>
                                <div style={panel.status === 'preview' ? styles.overlay : {}}>
                                    {panel.status === 'preview' && <div style={styles.overlayBefore}>Coming Soon</div>}
                                    <Link to={panel.link} style={panelLinkStyle}>
                                        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                                            {panel.icon}
                                            <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                                                {panel.title}
                                            </Typography>
                                        </Paper>
                                    </Link>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

export default HomePage;
